import { useRef, useEffect, useState } from 'react';
import './App.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, getDocs } from "firebase/firestore";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from 'uuid';

function App() {

  const messagesListRef = useRef<HTMLUListElement>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);

  // Initialize Firebase
  var firebaseConfig = {
    apiKey: "AIzaSyBSOoY1kkcdk3aZcD_W91_jyFnmMoz-OUk",
    authDomain: "eulyxlive.firebaseapp.com",
    projectId: "eulyxlive",
    storageBucket: "eulyxlive.appspot.com",
    messagingSenderId: "760226132552",
    appId: "1:760226132552:web:c711400871894f038b9e7d"
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  // Initialize Socket.io
  var socket = io();

  // Send message
  async function formHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    var message = messageInputRef.current!.value;
    const messageRef = doc(db, "messages", uuidv4());
    await setDoc(messageRef, {
      message: message,
      createdAt: new Date()
    });
    messageInputRef.current!.value = '';
  }

  // Listen for new messages
  socket.on('message', (message) => {
    var li = document.createElement('li');
    li.innerHTML = message.message;
    messagesListRef.current!.appendChild(li);
  });

  const [messages, setMessages] = useState([]);
  async function getAllMessages() {
    const querySnapshot = await getDocs(collection(db, "messages"));
    const msgs: any = [];
    querySnapshot.forEach((doc) => {
      msgs.push(doc.data());
    });
    setMessages(msgs);
  }

  useEffect(() => {
    getAllMessages();
  }, []);

  return (
    <div className="App">
      <ul id="messages" ref={messagesListRef}>
        {messages.map((message: any) => (
          <li key={message.createdAt}>{message.message}</li>
        ))}
      </ul>
      <form id="form" onSubmit={formHandler}>
        <input id="message" autoComplete="off" ref={messageInputRef}/><button>Send</button>
      </form>
    </div>
  );
}

export default App;
